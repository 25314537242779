import * as i0 from '@angular/core';
import { Injectable, Inject, NgModule } from '@angular/core';
import * as i1 from 'angular-oauth2-oidc';
import { WINDOW } from 'ngx-window-token';
import { ReplaySubject, filter, distinctUntilChanged, mergeMap, catchError, throwError } from 'rxjs';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
class PrivaAuthHttpOptions {}
const polyfill = {
  instance: () => ({
    jwt: {
      // eslint-disable-next-line @typescript-eslint/ban-types
      subscribe: (_success, error) => error('PrivaAuth is undefined.')
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    log: () => {}
  })
};
/**
 * PrivaAuthHttpService is responsible for subscribing to the JWT from PrivaAuth.js.
 */
class PrivaAuthHttpService {
  constructor(zone, oauthService, window, authHttpOptions) {
    this.zone = zone;
    this.oauthService = oauthService;
    this.window = window;
    this.authHttpOptions = authHttpOptions;
    this.jwt$ = new ReplaySubject();
    if (authHttpOptions.authConfig) {
      this.oauthService.events.pipe(filter(({
        type
      }) => ['discovery_document_loaded', 'silently_refreshed', 'token_received'].includes(type))).subscribe(() => {
        this.jwt$.next(this.oauthService.getAccessToken());
      });
    } else {
      this.PRIVA_AUTH = this.window.PrivaAuth || polyfill;
      // start jwt subscription
      this.PRIVA_AUTH.instance().jwt.subscribe(jwt => {
        this.zone.run(() => {
          this.jwt$.next(jwt);
        });
      }, err => {
        /* eslint-disable-next-line no-console */
        console.error(err);
        this.jwt$.next(null);
      });
    }
  }
  get jwt() {
    return this.jwt$.asObservable().pipe(distinctUntilChanged());
  }
  log() {
    if (!this.authHttpOptions.authConfig) {
      this.PRIVA_AUTH.instance().log();
    }
  }
  static {
    this.ɵfac = function PrivaAuthHttpService_Factory(t) {
      return new (t || PrivaAuthHttpService)(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.OAuthService), i0.ɵɵinject(WINDOW), i0.ɵɵinject(PrivaAuthHttpOptions));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaAuthHttpService,
      factory: PrivaAuthHttpService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaAuthHttpService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.NgZone
  }, {
    type: i1.OAuthService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [WINDOW]
    }]
  }, {
    type: PrivaAuthHttpOptions
  }], null);
})();
class HttpClientCsrfInterceptor {
  constructor(authOptions) {
    this.authOptions = authOptions;
  }
  intercept(req, next) {
    if (this.isSameSite(req.url)) {
      const csrfValue = this.authOptions.bffConfig?.csrfValue || '1';
      const duplicate = req.clone({
        setHeaders: {
          'x-csrf': csrfValue
        }
      });
      return next.handle(duplicate);
    } else {
      return next.handle(req);
    }
  }
  isSameSite(urlString) {
    const location = window.location;
    const url = new URL(urlString, location.href);
    return url.host === location.host && url.port === location.port;
  }
  static {
    this.ɵfac = function HttpClientCsrfInterceptor_Factory(t) {
      return new (t || HttpClientCsrfInterceptor)(i0.ɵɵinject(PrivaAuthHttpOptions));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HttpClientCsrfInterceptor,
      factory: HttpClientCsrfInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HttpClientCsrfInterceptor, [{
    type: Injectable
  }], () => [{
    type: PrivaAuthHttpOptions
  }], null);
})();
class PrivaAuthHttpBffModule {
  static forRoot(options) {
    return {
      ngModule: PrivaAuthHttpBffModule,
      providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: HttpClientCsrfInterceptor,
        multi: true
      }, options || {
        provide: PrivaAuthHttpOptions,
        useClass: PrivaAuthHttpOptions
      }]
    };
  }
  static {
    this.ɵfac = function PrivaAuthHttpBffModule_Factory(t) {
      return new (t || PrivaAuthHttpBffModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaAuthHttpBffModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaAuthHttpBffModule, [{
    type: NgModule,
    args: [{}]
  }], null, null);
})();
class AuthorizationHttpClientInterceptor {
  constructor(authService, authOptions) {
    this.authService = authService;
    this.authOptions = authOptions;
    this.loadedSubject = new ReplaySubject();
    // subscribe to jwt
    this.authService.jwt.subscribe({
      next: jwt => {
        const jwtIsSetForFirstTime = !this.jwt;
        this.jwt = jwt;
        if (jwtIsSetForFirstTime) {
          this.loadedSubject.next(true);
          this.loadedSubject.complete();
        }
      },
      /* eslint-disable-next-line no-console */
      error: err => console.error(err)
    });
  }
  intercept(req, next) {
    if (this.authOptions.authConfig) {
      return next.handle(req);
    } else {
      return this.loadedSubject.pipe(mergeMap(() => {
        if (this.bearerIsWhitelisted(req.url)) {
          const duplicate = req.clone({
            setHeaders: {
              Authorization: `Bearer ${this.jwt}`
            }
          });
          return next.handle(duplicate);
        } else {
          return next.handle(req);
        }
      }), catchError(this.catchAuthError(this)));
    }
  }
  catchAuthError(_self) {
    // we have to pass HttpService's own instance here as `self`
    return res => {
      if (res.status === 401 || res.status === 403) {
        /* eslint-disable-next-line */
      }
      return throwError(() => new Error(JSON.stringify(res)));
    };
  }
  bearerIsWhitelisted(url) {
    if (!this.authOptions.bearer || !this.authOptions.bearer.whitelist || this.authOptions.bearer.whitelist.length === 0 || this.authOptions.bearer.whitelist.some(_url => _url === '*')) {
      return true;
    }
    return this.authOptions.bearer.whitelist.some(_url => {
      return _url.startsWith('//') ? url.replace(/^https?:/gi, '').startsWith(_url) : url.startsWith(_url);
    });
  }
  static {
    this.ɵfac = function AuthorizationHttpClientInterceptor_Factory(t) {
      return new (t || AuthorizationHttpClientInterceptor)(i0.ɵɵinject(PrivaAuthHttpService), i0.ɵɵinject(PrivaAuthHttpOptions));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AuthorizationHttpClientInterceptor,
      factory: AuthorizationHttpClientInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthorizationHttpClientInterceptor, [{
    type: Injectable
  }], () => [{
    type: PrivaAuthHttpService
  }, {
    type: PrivaAuthHttpOptions
  }], null);
})();

/** @deprecated Use a BFF back-end and PrivaAuthHttpBffModule instead.
 * See https://dev.azure.com/privagroup/UX%20Frontend%20Toolkit/_git/priva.packages.monorepo?path=/projects/auth/MIGRATION-bff.md
 */
class PrivaAuthHttpModule {
  // Set standalone to true when your running the application without a frontend server, angular only.
  static forRoot(options) {
    return {
      ngModule: PrivaAuthHttpModule,
      providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: AuthorizationHttpClientInterceptor,
        multi: true
      }, options || {
        provide: PrivaAuthHttpOptions,
        useClass: PrivaAuthHttpOptions
      }]
    };
  }
  static {
    this.ɵfac = function PrivaAuthHttpModule_Factory(t) {
      return new (t || PrivaAuthHttpModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaAuthHttpModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaAuthHttpModule, [{
    type: NgModule,
    args: [{}]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaAuthHttpBffModule, PrivaAuthHttpModule, PrivaAuthHttpOptions, PrivaAuthHttpService };
