import * as i0 from '@angular/core';
import { Component, Optional, NgModule } from '@angular/core';
import * as i3 from 'angular-oauth2-oidc';
import { first } from 'rxjs';
import * as i2 from '@priva/auth/current-user';
import * as i1 from '@priva/auth/http';
import { PrivaAuthHttpModule } from '@priva/auth/http';
import { CommonModule } from '@angular/common';
import * as i1$1 from '@angular/router';
import { RouterModule } from '@angular/router';
const fallbackBffLogoutUrl = 'bff/logout';
class PrivaAuthSignoutComponent {
  constructor(authHttpOptions, userService, oauthService) {
    this.authHttpOptions = authHttpOptions;
    this.userService = userService;
    this.oauthService = oauthService;
  }
  ngOnInit() {
    if (this.authHttpOptions.bffConfig) {
      this.userService.user.pipe(first()).subscribe(({
        logoutUrl
      }) => {
        window.location.href = logoutUrl || fallbackBffLogoutUrl;
      });
    } else if (this.authHttpOptions.authConfig) {
      // Do a logout on the oauth service
      this.oauthService.logOut();
    } else {
      // Call the frontend server
      window.location.href = 'privaauth/logout';
    }
  }
  static {
    this.ɵfac = function PrivaAuthSignoutComponent_Factory(t) {
      return new (t || PrivaAuthSignoutComponent)(i0.ɵɵdirectiveInject(i1.PrivaAuthHttpOptions), i0.ɵɵdirectiveInject(i2.PrivaAuthCurrentUserService), i0.ɵɵdirectiveInject(i3.OAuthService, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: PrivaAuthSignoutComponent,
      selectors: [["ng-component"]],
      decls: 1,
      vars: 0,
      template: function PrivaAuthSignoutComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtext(0, "redirecting...");
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaAuthSignoutComponent, [{
    type: Component,
    args: [{
      template: 'redirecting...'
    }]
  }], () => [{
    type: i1.PrivaAuthHttpOptions
  }, {
    type: i2.PrivaAuthCurrentUserService
  }, {
    type: i3.OAuthService,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
const routes = [{
  path: '',
  component: PrivaAuthSignoutComponent
}];
class PrivaAuthSignoutRoutingModule {
  static {
    this.ɵfac = function PrivaAuthSignoutRoutingModule_Factory(t) {
      return new (t || PrivaAuthSignoutRoutingModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaAuthSignoutRoutingModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [RouterModule.forChild(routes), RouterModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaAuthSignoutRoutingModule, [{
    type: NgModule,
    args: [{
      imports: [RouterModule.forChild(routes)],
      exports: [RouterModule]
    }]
  }], null, null);
})();
class PrivaAuthSignoutModule {
  static {
    this.ɵfac = function PrivaAuthSignoutModule_Factory(t) {
      return new (t || PrivaAuthSignoutModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaAuthSignoutModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, PrivaAuthSignoutRoutingModule, PrivaAuthHttpModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaAuthSignoutModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PrivaAuthSignoutRoutingModule, PrivaAuthHttpModule],
      declarations: [PrivaAuthSignoutComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaAuthSignoutComponent, PrivaAuthSignoutModule, PrivaAuthSignoutRoutingModule };
